<template>
    <div>
        <v-dialog v-model="dialog" v-if="production && dialog" scrollable max-width="90%" persistent :transition="false">
          <v-card>
            <v-card-title>Hasil Timbang Request Stock</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="pt-2 font-weight-bold">
              Nomor Produksi: {{production.prod_no}}<br>
              Tanggal Produksi: {{production.production_date | moment}}
            </v-card-subtitle>
            <v-card style="height: 400px;" outlined class="mx-5 mt-0 mb-5">
              <v-simple-table fixed-header height="350px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nama Bahan</th>
                      <th class="text-center">Request Stock</th>
                      <th class="text-center">Request Spare</th>
                      <th class="text-center">Current Stock</th>
                      <th class="text-center">Opening Stock</th>
                      <th class="text-center">Hasil Timbang (aktual)</th>
                      <th class="text-center">Hasil Timbang (spare)</th>
                      <th class="text-center">Ending Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj, idx) in production.details" :key="idx">
                      <td class="text-center text-no-wrap">{{obj.material_name}} ({{obj.uom}})</td>
                      <td class="text-center">{{Number(obj.qty_request) | numbers}}</td>
                      <td class="text-center">{{Number(obj.qty_spare_request) | numbers}}</td>
                      <td class="text-center">{{obj.stock_quantity}}</td>
                      <td class="text-center">{{obj.opening_stock}}</td>
                      <td class="text-center">
                        <v-text-field class="compact-form" v-model="obj.weighing_actual" outlined dense hide-details="auto" style="width:100px" :suffix="obj.uom"></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="compact-form" v-model="obj.weighing_spare" outlined dense hide-details="auto" style="width:100px" :suffix="obj.uom"></v-text-field>
                      </td>
                      <td class="text-center">{{obj.opening_stock-obj.weighing_actual-obj.weighing_spare}}</td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>     
            </v-card>
            <v-divider></v-divider>
            <v-card-actions class="mr-3">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save 
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data: () => ({
      dialog: false,
      production: null,
      coldPressed: false,
      onProgress: false,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }
    },

    methods: {
      async openDialog(obj) {
          this.dialog = true
          this.onProgress = true
          this.production = await this.$store.dispatch('production/getObj', obj.prod_no)
          this.production.details.sort((a,b) => a.material_name.localeCompare(b.material_name));
          this.production.details.forEach(item => {
            item.opening_stock = Number(item.stock_quantity) + Number(item.weighing_actual) + Number(item.weighing_spare)
          })
          console.log(this.production.details)
          this.onProgress = false
      },
      async save() {
        this.onProgress = true
        this.production.status = 'SUPPLIED'
        this.cleanObj(this.production)
        this.production.details.forEach(obj => {
            if (obj.weighing_actual===null) obj.weighing_actual = 0
            if (obj.weighing_spare===null) obj.weighing_spare = 0
            this.cleanObj(obj)
        })
        console.log(this.production)
        const docs = await this.$store.dispatch('production/putObj', this.production)
        if (docs.status == 200) {
          this.onProgress = false
          this.dialog = false
          this.$emit('refresh')
        }
      },
    }    
}
</script>