<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="prod_no"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" small depressed @click="initialize">Refresh</v-btn>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="inputStock(item)" v-if="item.status=='SUPPLIED'">
                    mdi-pencil
                </v-icon>              
                <v-btn v-if="item.status=='REQUEST'" color="green" small depressed @click="inputStock(item)" class="mr-2 white--text" style="text-transform: none;">Input Stock</v-btn>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_date`]="{ item }">
              {{item.production_date | moment}}
            </template>            
            <template v-slot:[`item.status`]="{ item }">
              {{(item.status)? getStatusName(item.status) : 'Pending' }}
            </template>            

        </v-data-table>
        
        <input-stock ref="refInputStock" @refresh="initialize"></input-stock>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import InputStock from '../components/InputStock.vue'
export default {
    components: { InputStock },
    data: () => ({
      headers: [
        { text: 'No. Produksi', value: 'prod_no' },
        { text: 'Tanggal Produksi', value: 'production_date' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      editDialog: false,
      materials: [],
      selectedProd: null,
      onProgress: false,
      interval: null
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodStatus() {
        return this.miscCodes.filter(element => {
          return element.type=='Status Produksi'
        })
      },
    },

    watch: {
    },

    created () {
      this.initialize()
      this.interval = setInterval(async ()=> {
        await this.initialize()
      }, 60000)
    },

    beforeRouteLeave (to, from, next) {
      clearInterval(this.interval)
      next()
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        }
    },

    methods: {
      async initialize () {
        console.log('initialized')
        this.items = await this.$store.dispatch('production/getProductionsByStatus', 'REQUEST')

      },

      getStatusName(code) {
        return (this.prodStatus.find(el => {return el.code==code})).description
      },

      inputStock(obj) {
        this.$refs.refInputStock.coldPressed = false
        this.$refs.refInputStock.openDialog(obj)
      },

    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>